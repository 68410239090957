import React, { useState, useEffect } from "react";
import { BiGitPullRequest } from "react-icons/bi";
import { IoIosCloseCircle } from "react-icons/io";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

const ErrorModal = ({ message, onClose }) => (
  <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
    <div className="bg-white p-6 rounded-lg shadow-lg">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold">Error</h2>
        <IoIosCloseCircle
          onClick={onClose}
          className="w-[29px] h-[29px] cursor-pointer"
        />
      </div>
      <p>{message}</p>
      <div className="flex justify-end mt-4">
        <button
          onClick={onClose}
          className="bg-red-500 text-white px-4 py-2 rounded-lg"
        >
          Close
        </button>
      </div>
    </div>
  </div>
);

const RequestedLeaves = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);
  const [leaveType, setLeaveType] = useState("");
  const [reason, setReason] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [blockedDates, setBlockedDates] = useState([]);
  const [showErrorModal, setShowErrorModal] = useState(false);

  useEffect(() => {
    const fetchBlockedDates = async () => {
      try {
        const response = await fetch(
          "https://back-demo-british.talentfort.live/v1/hris/leave/getleavedates"
        );
        const data = await response.json();
        const blocked = data.map((item) => new Date(item.leave_date));
        setBlockedDates(blocked);
      } catch (error) {
        console.error("Error fetching blocked dates:", error);
      }
    };

    fetchBlockedDates();
  }, []);

  const togglePopup = () => {
    setShowPopup(!showPopup);
    setErrorMessage(""); // Clear error message when popup is toggled
  };

  const handleDateChange = (date) => {
    if (blockedDates.find((d) => d.toDateString() === date.toDateString())) {
      setErrorMessage("You can't get a leave this day.");
      setShowErrorModal(true);
      return;
    }

    if (selectedDates.find((d) => d.toDateString() === date.toDateString())) {
      setSelectedDates(
        selectedDates.filter((d) => d.toDateString() !== date.toDateString())
      );
    } else {
      setSelectedDates([...selectedDates, date]);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!leaveType || selectedDates.length === 0 || !reason) {
      setErrorMessage("All fields are required.");
      setShowErrorModal(true);
      return;
    }

    const formattedDates = selectedDates.map(
      (date) => date.toISOString().split("T")[0]
    );
    const data = {
      leave_type: leaveType,
      employee_no: localStorage.getItem("employee_no"), // Retrieve from local storage
      requested_dates: formattedDates,
      reason: reason,
    };

    console.log("Sending data:", data); // Log the data being sent

    try {
      const response = await fetch(
        "https://back-demo-british.talentfort.live/v1/hris/leave/addleave",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        }
      );
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to submit leave request");
      }
      alert("Leave request submitted successfully!");
      setShowPopup(false); // Close the popup
    } catch (error) {
      console.error("Error submitting leave request:", error);
      setErrorMessage(error.message);
      setShowErrorModal(true);
    }
  };

  return (
    <div className={showPopup ? "bg-B2B7D1" : ""}>
      <div
        className="shadow-lg bg-Btn w-[285px] p-2 rounded-[10px] font-sans"
        onClick={togglePopup}
      >
        <div className="flex items-center gap-3 justify-center">
          <BiGitPullRequest className="text-white h-[25px] w-[25px]" />
          <p className="text-white text-[20px]">Request for Leaves</p>
        </div>
      </div>
      {showPopup && (
        <div className="font-sans font-semibold fixed inset-0 flex items-center justify-center">
          <div className="absolute inset-0 bg-opacity-70 bg-[#B2B7D1]"></div>
          <div className="bg-white w-[495px] p-6 rounded-lg relative">
            <div className="flex justify-between items-center">
              <p className="text-xl font-semibold mb-4 text-[20px] font-sans">
                Requested for leave
              </p>
              <IoIosCloseCircle
                onClick={togglePopup}
                className="w-[29px] h-[29px] cursor-pointer"
              />
            </div>
            <form className="flex flex-col" onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-sm text-gray-700">Type</label>
                <select
                  value={leaveType}
                  onChange={(e) => setLeaveType(e.target.value)}
                  className="mt-1 p-2 w-full rounded-[10px] border border-gray-300"
                >
                  <option value="">Select Leave Type</option>
                  <option value="Casual Leave">Casual Leave</option>
                  <option value="Medical Leave">Medical Leave</option>
                  <option value="Short Leave">Short Leave</option>
                </select>
              </div>
              <div>
                <label className="block text-sm text-gray-700">
                  Select Dates
                </label>
                <DatePicker
                  selected={null} // to avoid showing selected date
                  onChange={handleDateChange}
                  inline
                  highlightDates={selectedDates}
                  dayClassName={(date) =>
                    blockedDates.find(
                      (d) => d.toDateString() === date.toDateString()
                    )
                      ? "highlighted-blocked-date"
                      : undefined
                  }
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm text-gray-700">Reason</label>
                <textarea
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  className="w-full h-20 border border-gray-300 rounded-[10px] p-2"
                />
              </div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="bg-black text-white px-4 py-2 rounded-lg"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {showErrorModal && (
        <ErrorModal
          message={errorMessage}
          onClose={() => setShowErrorModal(false)}
        />
      )}
    </div>
  );
};

export default RequestedLeaves;
