import React from "react";
import "./loader.css";
function loader() {
  return (
    <div className="flex justify-center items-center mt-[400px]">
      <div class="loader"></div>
    </div>
  );
}

export default loader;
