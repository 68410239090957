import React, { useState, useEffect } from "react";
import { FaCalendarAlt } from "react-icons/fa";
import { IoIosCloseCircle } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const LeavesTable = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [leaveData, setLeaveData] = useState([]);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [editData, setEditData] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [leaveType, setLeaveType] = useState("");
  const [reason, setReason] = useState("");
  const [isAscending, setIsAscending] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const toggleEditPopup = (data) => {
    if (data) {
      setEditData(data);
      setLeaveType(data.leave_type);
      setSelectedDate(new Date(data.requested_date));
      setReason(data.reason);
    }
    setShowEditPopup(!showEditPopup);
  };

  useEffect(() => {
    if (showPopup) {
      const employeeNo = localStorage.getItem("employee_no");

      if (employeeNo) {
        fetch(
          `https://back-demo-british.talentfort.live/v1/hris/leave/getemployeeleavedetails?employee_no=${employeeNo}`
        )
          .then((response) => response.json())
          .then((data) => {
            setLeaveData(data.data || []);
          })
          .catch((error) => {
            console.error("Error fetching leave data:", error);
          });
      } else {
        console.error("Employee number not found in local storage");
      }
    }
  }, [showPopup]);

  useEffect(() => {
    if (leaveData.length > 0) {
      sortLeaveData();
    }
  }, [leaveData, isAscending]);

  const handleUpdateLeave = (e) => {
    e.preventDefault();

    const updatedLeave = {
      employee_no: "EMP002",
      leave_type: leaveType,
      requested_date: selectedDate.toISOString().split("T")[0],
      reason: reason,
    };

    fetch(
      `https://back-demo-british.talentfort.live/v1/hris/leave/updateleave?id=${editData.id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedLeave),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Update successful:", data);
        // Update the leave data in the table
        setLeaveData((prevData) =>
          prevData.map((item) =>
            item.id === editData.id ? { ...item, ...updatedLeave } : item
          )
        );
        toggleEditPopup(null); // Close the edit popup
      })
      .catch((error) => {
        console.error("Error updating leave data:", error);
      });
  };

  // Function to get the color for the leave type
  const getColorForLeaveType = (type) => {
    switch (type) {
      case "Casual Leave":
        return "bg-blue-500";
      case "Annual Leave":
        return "bg-pink-500";
      case "Short Leave":
        return "bg-yellow-500";
      case "Medical Leave":
        return "bg-purple-500";
      default:
        return "bg-gray-500";
    }
  };

  // Function to get the color class for the status
  const getStatusColorClass = (status) => {
    switch (status) {
      case "APPROVE":
        return "text-green-600 border-green-600 bg-green-100";
      case "PENDING":
        return "text-yellow-600 border-yellow-600 bg-yellow-100";
      case "REJECT":
        return "text-red-600 border-red-600 bg-red-100";
      default:
        return "text-gray-600 border-gray-600 bg-gray-100";
    }
  };

  // Function to get the label for the status
  const getStatusLabel = (status) => {
    switch (status) {
      case "APPROVE":
        return "Approved";
      case "PENDING":
        return "Pending";
      case "REJECT":
        return "Rejected";
      default:
        return "Unknown";
    }
  };

  // Function to sort the leave data by date
  const sortLeaveData = () => {
    const sortedData = [...leaveData].sort((a, b) => {
      return isAscending
        ? new Date(a.requested_date) - new Date(b.requested_date)
        : new Date(b.requested_date) - new Date(a.requested_date);
    });
    setLeaveData(sortedData);
  };

  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calculate the rows to display based on the current page
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = leaveData.slice(indexOfFirstRow, indexOfLastRow);

  return (
    <div className={showPopup ? "bg-B2B7D1" : ""}>
      <div>
        <div
          className="shadow-lg bg-Btn w-[285px] p-2 rounded-[10px]"
          onClick={togglePopup}
        >
          <div className="flex items-center gap-5 justify-center">
            <div>
              <FaCalendarAlt className="text-white h-[25px] w-[25px]" />
            </div>
            <div>
              <p className="text-white text-[20px] flex justify-center">
                View Leave Table
              </p>
            </div>
          </div>
        </div>
      </div>

      {showPopup && (
        <div className="font-sans font-semibold fixed inset-0 flex items-center justify-center ">
          <div
            className="absolute inset-0 bg-opacity-70 bg-[#B2B7D1]"
            onClick={togglePopup}
          ></div>
          <div className="bg-white w-[850px] h-[550px] p-6 rounded-lg relative">
            <div className="flex justify-between items-center">
              <div>
                <p className="text-xl font-semibold mb-4 text-[20px] font-sans">
                  LEAVES : Table View
                </p>
              </div>

              <div>
                <IoIosCloseCircle
                  onClick={togglePopup}
                  className="w-[29px] h-[29px] cursor-pointer"
                />
              </div>
            </div>
            {/* Form */}

            <div className="w-[800px] h-[400px] overflow-y-auto">
              <table className="w-full">
                <thead className="bg-[#252C58] text-white w-[800px]">
                  <tr>
                    <th className="p-2 flex items-center ">
                      <input
                        type="checkbox"
                        className="form-checkbox justyfy-center flex ml-10 mr-2"
                        // onClick={() => setIsAscending(!isAscending)}
                      />
                      Select All
                    </th>
                    <th className="p-2 ">Status</th>
                    <th className="p-2">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentRows.map((item) => (
                    <tr key={item.id} className="border-b border-gray-200">
                      <td className="p-2 flex items-center">
                        <input
                          type="checkbox"
                          className="form-checkbox ml-10 mr-3"
                        />
                        <span
                          className={`inline-block w-4 h-4 rounded-full mr-2 ${getColorForLeaveType(
                            item.leave_type
                          )}`}
                        ></span>
                        <div>
                          <div className="text-gray-900 font-medium">
                            {item.requested_date}
                          </div>
                          <div className="text-gray-500">{item.leave_type}</div>
                        </div>
                      </td>
                      <td className="p-2 text-center">
                        <span
                          className={`inline-block px-3 py-1 rounded-full text-sm font-semibold border ${getStatusColorClass(
                            item.approved_status_1
                          )}`}
                        >
                          <span
                            className={`inline-block w-2 h-2 rounded-full mr-1 ${
                              item.approved_status_1 === "APPROVE"
                                ? "bg-green-600"
                                : item.approved_status_1 === "PENDING"
                                ? "bg-yellow-600"
                                : "bg-red-600"
                            }`}
                          ></span>
                          {getStatusLabel(item.approved_status_1)}
                        </span>
                      </td>
                      <td className="p-2 text-center">
                        <button
                          className="text-gray-600 hover:text-red-600"
                          onClick={() => toggleEditPopup(item)}
                        >
                          <MdDelete />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="flex justify-between items-center mt-4">
              <button
                className="bg-gray-200 text-gray-700 py-1 px-3 rounded-md"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <div className="space-x-1">
                {Array.from(
                  { length: Math.ceil(leaveData.length / rowsPerPage) },
                  (_, index) => (
                    <button
                      key={index + 1}
                      className={`bg-gray-200 text-gray-700 py-1 px-2 rounded-md ${
                        currentPage === index + 1 ? "bg-gray-400" : ""
                      }`}
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  )
                )}
              </div>
              <button
                className="bg-gray-200 text-gray-700 py-1 px-3 rounded-md"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={
                  currentPage === Math.ceil(leaveData.length / rowsPerPage)
                }
              >
                Next
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LeavesTable;
