import React from "react";
import { Navigate } from "react-router-dom";

function ProtectedRoute({ children }) {
  const isAuthenticated = localStorage.getItem("token"); // Check if token is present

  if (!isAuthenticated) {
    // User is not authenticated, redirect to login page
    return <Navigate to="/" replace />;
  }

  return children; // User is authenticated, render the child component
}

export default ProtectedRoute;
