import React, { useState, useEffect } from "react";
import Logo from "../../assets/logo.png";
import { FaUser } from "react-icons/fa";
import { BiLogOutCircle } from "react-icons/bi";
import { IoIosArrowDown, IoMdCloseCircle } from "react-icons/io";
import RequestedLeaves from "../requested_leaves/requested_leaves";
import LeavesTable from "../leaves_table/leaves_table";

const Home = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [showDropdown, setShowDropdown] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [employeeName, setEmployeeName] = useState("");

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    return () => clearInterval(timerID);
  }, []);

  const tick = () => {
    setCurrentTime(new Date());
  };

  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    const suffix = (day) => {
      if (day >= 11 && day <= 13) {
        return "th";
      }
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };
    return `${day}${suffix(day)} ${month.toUpperCase()} ${year}`;
  };

  const handleDone = async (event) => {
    event.preventDefault(); // Prevent the form from submitting through the browser

    if (!oldPassword || !newPassword || !confirmPassword) {
      setErrorMessage("Please fill in all fields.");
      return;
    }

    if (newPassword !== confirmPassword) {
      setErrorMessage("New Password and Confirm Password do not match.");
      return;
    }

    const email = localStorage.getItem("email"); // Retrieve the email from localStorage
    if (!email) {
      setErrorMessage("User email not found.");
      return;
    }

    // Prepare the request data
    const data = JSON.stringify({
      email: email,
      oldPassword: oldPassword,
      newPassword: newPassword,
    });

    try {
      const response = await fetch(
        "https://back-demo-british.talentfort.live/v1/hris/login/resetpassword",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: data,
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        setErrorMessage(errorData.message || "Failed to reset password.");
        return;
      }

      // Handle success
      alert("Password reset successfully.");
      setShowPopup(false); // Close the popup
    } catch (error) {
      console.error("Error resetting password:", error);
      setErrorMessage("Network error. Please try again later.");
    }
  };

  const handleLogout = () => {
    // Clear local storage and redirect to login
    localStorage.clear();
    window.location.href = "/";
  };

  useEffect(() => {
    // Retrieve user information from local storage
    const storedEmail = localStorage.getItem("email");
    const storedEmployeeId = localStorage.getItem("employee_no");
    const storedEmployeeName = localStorage.getItem("employee_name");

    setEmployeeName(storedEmployeeName || "");
    setEmail(storedEmail || "");
    setEmployeeId(storedEmployeeId || "");
  }, []);

  return (
    <div className="bg-background h-screen overflow-y-auto">
      <div className="flex justify-center mt-[69px] font-sans">
        <div className="shadow-lg bg-white w-[1229px] h-[83px] rounded-[24px] ">
          <div className="flex justify-between items-center mt-3 px-4">
            {/* logo */}
            <div>
              <img src={Logo} alt="logo" className="h-[57px] w-[105px]" />
            </div>

            {/* user */}
            <div className="flex items-center gap-5">
              <div>
                <FaUser className="w-[20px] h-[20px]" />
              </div>

              <div>
                <p>{employeeName}</p>
                <p>{employeeId}</p>
              </div>
              <div
                className="ml-4"
                onClick={() => setShowDropdown(!showDropdown)}
              >
                <IoIosArrowDown className="w-[24px] h-[21px] cursor-pointer" />
                {showDropdown && (
                  <div className="absolute bg-white rounded-[30px] shadow-lg mt-1">
                    {/* Dropdown content */}
                    <p
                      className="py-2 cursor-pointer px-4"
                      onClick={() => setShowPopup(true)}
                    >
                      Reset Password
                    </p>
                  </div>
                )}
              </div>

              <div className="ml-4">
                <BiLogOutCircle
                  className="w-[24px] h-[21px] cursor-pointer"
                  onClick={handleLogout}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* System time */}
      <div className="flex justify-center mt-[5%] px-4">
        <div className=" shadow-lg bg-white w-[285px] h-[195px] rounded-[15px]">
          <div className="flex justify-center mt-4">
            <p className="text-[#252C58] font-sans text-[36px] font-bold">
              {" "}
              {currentTime.toLocaleTimeString()}
            </p>
          </div>
          <div className="flex justify-center font-sans">
            <p className="text-[#252C58] text-sm">Real Time Insight</p>
          </div>

          <div className="px-8 font-sans">
            <p className="text-[18px] mt-[10px] font-sans">Today </p>
            <p className="text-[#252C58] font-bold text-[20px]">
              {formatDate(currentTime)}
            </p>
          </div>
        </div>
      </div>

      <div className="flex justify-center mt-[2%] cursor-pointer">
        <RequestedLeaves />
      </div>

      <div className="flex justify-center mt-[2%] cursor-pointer">
        <LeavesTable />
      </div>

      {/* Popup */}
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-[#B2B7D1] bg-opacity-70">
          <div className="bg-white p-8 rounded-[25px] shadow-[25px] w-[495px] h-[441px]">
            <div className="flex  items-center justify-between">
              <h2 className="text-xl font-semibold mb-4 font-sans">
                Reset Password
              </h2>
              <IoMdCloseCircle
                onClick={() => setShowPopup(false)}
                className="w-[25px] h-[25px] cursor-pointer"
              />
            </div>

            <form className="font-sans font-semibold">
              <div className="mb-4">
                <label className="block text-sm text-gray-700">
                  Old Password
                </label>
                <input
                  type="password"
                  className="mt-1 p-2 border border-gray-300 rounded-[15px] w-full"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm text-gray-700">
                  New Password
                </label>
                <input
                  type="password"
                  className="mt-1 p-2 border border-gray-300 rounded-[15px] w-full"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm text-gray-700">
                  Confirm Password
                </label>
                <input
                  type="password"
                  className="mt-1 p-2 border border-gray-300 rounded-[15px] w-full"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>

              {errorMessage && (
                <p className="text-red-500 mb-4">{errorMessage}</p>
              )}

              <div className="flex justify-end">
                <button
                  onClick={handleDone}
                  className="bg-Btn text-white px-4 py-2 rounded-lg"
                >
                  Done
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
