import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FiEye, FiEyeOff } from "react-icons/fi";
import HRIS_Logo from "../../assets/LOGO HRIS 5.png";
import Login_Logo from "../../assets/login-image.png";
import Loader from "../loders/loader";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const handleRememberMe = () => setRememberMe(!rememberMe);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Set loading to true before the request
    try {
      const response = await fetch(
        "https://back-demo-british.talentfort.live/v1/hris/login/login",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email, password }),
        }
      );
      const data = await response.json();
      if (data.success) {
        console.log("Login Successful:", data);

        // Store data in local storage
        localStorage.setItem("token", data.token);
        localStorage.setItem("employee_no", data.employee_no);
        localStorage.setItem("email", data.email);
        localStorage.setItem("employee_name", data.employee_name);

        navigate("/home");
      } else {
        alert("Login failed: " + (data.message || "Unknown error"));
        setIsLoading(false); // Reset loading state if login fails
      }
    } catch (error) {
      console.error("Login error:", error);
      alert("Login failed: Network or server error.");
      setIsLoading(false); // Reset loading state on error
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <div className="flex gap-[15%]">
        <div className="ml-[8%] mt-[3%]">
          <img
            src={HRIS_Logo}
            alt="HRIS Logo"
            className="w-[220px] h-[220px]"
          />
          <p className="text-[40px] font-semibold font-sans">Employee Login</p>
          <p className="text-sm font-sans mt-[3%]">
            Login to access your travelwise account
          </p>

          <form onSubmit={handleSubmit}>
            {/* Email input */}
            <div className="flex flex-col w-fit static">
              <label
                htmlFor="email"
                className="text-md font-semibold relative top-2 ml-[7px] px-[3px] bg-white w-fit"
              >
                Email
              </label>
              <input
                id="email"
                type="email"
                placeholder="Write here..."
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="border-black px-[10px] py-[11px] text-ml bg-white border-2 rounded-[54px] h-[56px] w-[512px] focus:outline-none placeholder:text-black/25"
              />
            </div>

            {/* Password input */}
            <div className="flex flex-col w-fit static">
              <label
                htmlFor="password"
                className="text-md font-semibold relative top-2 ml-[7px] px-[3px] bg-white w-fit z-50"
              >
                Password
              </label>
              <div className="relative">
                <input
                  id="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Write here..."
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="border-black px-[10px] py-[11px] text-ml bg-white border-2 rounded-[54px] h-[56px] w-[512px] focus:outline-none placeholder:text-black/25"
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 flex items-center pr-[10px] focus:outline-none"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? (
                    <FiEyeOff className="text-[#252C58] h-[20px] w-[20px]" />
                  ) : (
                    <FiEye className="text-[#252C58] h-[20px] w-[20px]" />
                  )}
                </button>
              </div>
            </div>

            {/* Remember Me checkbox */}
            <div className="mt-[4%] flex items-center">
              <input
                type="checkbox"
                id="rememberMe"
                checked={rememberMe}
                onChange={handleRememberMe}
                className="mr-2"
              />
              <label htmlFor="rememberMe" className="text-sm font-sans">
                Remember Me
              </label>
            </div>

            {/* Login button */}
            <div className="mt-[4%]">
              <button className="bg-[#252C58] rounded-[63px] text-white w-[512px] h-[48px] font-sans">
                Login
              </button>
            </div>
          </form>
        </div>

        {/* Login image */}
        <div>
          <img
            src={Login_Logo}
            alt="Login visual"
            className="h-[670px] w-[500px]"
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
